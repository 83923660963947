import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SubHeader from "../components/SubHeader";
import { NavLink } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";

const phone = "7069388700"; // phone number
const mobileURL = `whatsapp://send?text=Thank you for your connection with Nexi Sphere! If there are anything specific you like to know or discuss, please let me know.&phone=${phone}`;
const webURL = `https://wa.me/${phone}?text=Thank you for your connection with ANexi Sphere! If there are anything specific you like to know or discuss, please let me know.`;

export const handleWhatsup = () => {
  if (/Mobi|Android/i.test(navigator.userAgent)) {
    window.open(mobileURL, "_blank");
  } else {
    window.open(webURL, "_blank");
  }
};

export const sendEmail = () => {
  window.location = "mailto:connect@nexispherealliance.com";
};

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(false);

  const validateForm = () => {
    if (name && email && subject && message) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    validateForm();
  };

  return (
    <>
      <SubHeader subHeaderText="Contact" />
      <div className="tb-contant-form01">
        <Container>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <div className="contact_form_section">
                <h3>Send Message to Us</h3>
                <form>
                  <Row>
                    <Col md={12}>
                      <div className="form_block">
                        <input
                          className="form-control require"
                          type="text"
                          name="name"
                          placeholder="Enter Your Name"
                          value={name}
                          onChange={handleInputChange(setName)}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form_block">
                        <input
                          type="email"
                          name="email"
                          placeholder=" Enter Your Email"
                          className="form-control require"
                          value={email}
                          onChange={handleInputChange(setEmail)}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form_block">
                        <input
                          type="text"
                          name="subject"
                          size="40"
                          placeholder="Enter Your Subject"
                          className="form-control require"
                          value={subject}
                          onChange={handleInputChange(setSubject)}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form_block">
                        <textarea
                          name="message"
                          cols="40"
                          rows="10"
                          placeholder="Type Message..."
                          className="form-control require"
                          value={message}
                          onChange={handleInputChange(setMessage)}
                          required
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <button
                    type="submit"
                    onClick={sendEmail}
                    disabled={!isValid}
                    style={{
                      backgroundColor: !isValid ? "gray" : "#712885",
                      cursor: !isValid ? "not-allowed" : "pointer",
                    }}
                  >
                    Send Message
                  </button>
                  <div className="response"></div>
                </form>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <div className="tp-form-text">
                <p>GET IN TOUCH WITH US</p>
                <h2>We provide quality raw banana powder for your needs</h2>
                <h5>
                  Our raw banana powder is made from high-quality bananas,
                  ensuring the best nutrition and taste for your culinary
                  creations.
                </h5>
              </div>
              <div className="tb-inner-contact-taxt">
                <h4>Our Location</h4>
                <div className="tp-info">
                  <i className="fas fa-envelope"></i>
                  <p className="tp-aheto-contact">
                    connect@nexispherealliance.com
                  </p>
                </div>
                <div className="tp-info">
                  <i className="fas fa-phone"></i>
                  <p className="tp-aheto-contact">(+91) 70693 88700</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div
          className="ct-section2"
          style={{
            padding: "80px 10px",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "20px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div
              className="aligncenter"
              onClick={sendEmail}
              style={{ cursor: "pointer" }}
            >
              <i
                style={{ fontSize: "44px", color: "#712885" }}
                className="fa fa-envelope-o"
              ></i>
              <div className="demo_space" style={{ height: "20px" }}></div>
              <h5>Have any questions?</h5>
              <hr className="ct-hr" />
              <h5>connect@nexispherealliance.com</h5>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <div
              className="aligncenter"
              onClick={handleWhatsup}
              style={{ cursor: "pointer" }}
            >
              <i
                style={{ fontSize: "44px", color: "#712885" }}
                className="fa-brands fa-whatsapp"
              ></i>
              <div className="demo_space" style={{ height: "20px" }}></div>
              <h5>WhatsApp</h5>
              <hr className="ct-hr" />
              <h5>(+91)706938 8700</h5>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <NavLink to="tel:70693 88700">
              <div className="aligncenter">
                <i
                  style={{ fontSize: "44px", color: "#712885" }}
                  className="fa fa-phone"
                ></i>
                <div className="demo_space" style={{ height: "20px" }}></div>
                <h5>Contact Us</h5>
                <hr className="ct-hr" />
                <h5>(+91)70693 88700</h5>
              </div>
            </NavLink>
          </div>

          <div className="clear"></div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
