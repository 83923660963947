import { Link, NavLink } from "react-router-dom";
// import { windowScroll } from "./Footer";
import { useEffect, useState } from "react";
import nexiSphereLogo from "../assets/images/nexiSphereLogo.png";
import { windowScroll } from "./Footer";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 959);

  // Detect window resize to switch between mobile and desktop view
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 959);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  // Close the mobile menu after clicking a link
  const closeMobileMenu = () => {
    if (isMobileView) {
      setIsMobileMenuOpen(false);
      setIsProductDropdownOpen(false); // Close dropdown as well
    }
  };

  const childIsActive = () => {
    const path = window.location.pathname;
    return (
      path.includes("/ripe-banana-powder") ||
      path.includes("/green-banana-powder") ||
      path.includes("/organic-banana-powder") ||
      path.includes("/banana-flour") ||
      path.includes("/spray-dried-banana-powder") ||
      path.includes("/freeze-dried-banana-powder") ||
      path.includes("/dehydrated-banana-powder") ||
      path.includes("/instant-banana-powder")
    );
  };

  return (
    <header>
      <div id="fixedheader" className="header">
        <div className="header-area">
          <div className="logo">
            <Link to="/">
              <img src={nexiSphereLogo} alt="nexi-sphere" height="80" />
            </Link>
          </div>

          <div className="header-rightpart">
            <div className="primarymenu">
              <ul className="sf-menu">
                <li
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to="/"
                    style={({ isActive }) => ({
                      color: isActive ? "#712885" : "black",
                    })}
                  >
                    Home
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to="/about-us"
                    style={({ isActive }) => ({
                      color: isActive ? "#712885" : "black",
                    })}
                  >
                    About Us
                  </NavLink>
                </li>
                <li
                  className="menu-item menu-item-has-children"
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to="#"
                    style={() => ({
                      color: childIsActive() ? "#712885" : "black",
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsProductDropdownOpen(!isProductDropdownOpen);
                    }}
                  >
                    Products
                  </NavLink>
                  {isProductDropdownOpen && (
                    <ul className="sub-menu">
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/ripe-banana-powder">
                          Ripe Banana Powder
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/green-banana-powder">
                          Green Banana Powder (Plantain Flour)
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/organic-banana-powder">
                          Organic Banana Powder
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/banana-flour">Banana Flour</NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/spray-dried-banana-powder">
                          Spray-Dried Banana Powder
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/freeze-dried-banana-powder">
                          Freeze-Dried Banana Powder
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/dehydrated-banana-powder">
                          Dehydrated Banana Powder
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/instant-banana-powder">
                          Instant Banana Powder
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>

                <li
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to="/contact-us"
                    style={({ isActive }) => ({
                      color: isActive ? "#712885" : "black",
                    })}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          {/* Mobile Menu Toggle Button */}
          {isMobileView && (
            <div
              id="iva-mobile-nav-icon"
              className="iva-mobile-dropdown"
              onClick={toggleMobileMenu}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && isMobileView && (
          <div
            style={{
              backgroundColor: "#ab88b8", // Light background for contrast
              padding: "10px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
            }}
          >
            <ul
              className="iva_mmenu"
              style={{ listStyle: "none", padding: "0", margin: "0" }}
            >
              {["/", "/about-us", "/contact-us"].map((path, index) => (
                <li
                  key={index}
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to={path}
                    style={{
                      display: "block",
                      padding: "15px 20px", // Increased padding for better touch target
                      textDecoration: "none",
                      color: "white", // Primary color for the links
                      transition: "background-color 0.3s", // Smooth transition for hover effect
                    }}
                  >
                    {path === "/"
                      ? "HOME"
                      : path.split("/")[1].replace("-", " ").toUpperCase()}
                  </NavLink>
                </li>
              ))}
              <li
                onClick={(e) => {
                  e.preventDefault();
                  setIsProductDropdownOpen(!isProductDropdownOpen);
                }}
              >
                <NavLink
                  to="#"
                  style={{
                    display: "block",
                    padding: "15px 20px",
                    textDecoration: "none",
                    color: "white",
                    position: "relative",
                    transition: "background-color 0.3s",
                  }}
                >
                  PRODUCT
                  <span
                    className="iva-children-indenter"
                    style={{ marginLeft: "5px" }}
                  >
                    <i
                      className="fa fa-angle-down"
                      style={{ color: "white" }}
                    ></i>
                  </span>
                </NavLink>
                {isProductDropdownOpen && (
                  <ul
                    className="sub-menu"
                    style={{
                      listStyle: "none",
                      padding: "0",
                      margin: "0",

                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                      position: "absolute",
                      left: "0",
                      right: "0",
                      zIndex: "10",
                    }}
                  >
                    {[
                      "/ripe-banana-powder",
                      "/green-banana-powder",
                      "/organic-banana-powder",
                      "/banana-flour",
                      "/spray-dried-banana-powder",
                      "/freeze-Dried Banana Powder",
                      "/dehydrated-banana-powder",
                      "/instant-banana-powder",
                    ].map((path, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink
                          to={path}
                          style={{
                            display: "block",
                            padding: "10px 60px",
                            textDecoration: "none",
                            transition: "background-color 0.3s",
                            backgroundColor: "#ab88b8",
                            color: "white",
                            margin: "-1px 0",
                          }}
                        >
                          {path.split("/")[1].replace("-", " ").toUpperCase()}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
