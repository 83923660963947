import React from "react";
import { NavLink } from "react-router-dom";
import banner from "../assets/images/banner.png";
import Banner from "../components/Banner";
import { windowScroll } from "../components/Footer";

const HomePage = () => {
  return (
    <div>
      <Banner />
      <div className="homepage">
        <section class="at-fancy-heading-v1  main-text">
          <div className="textcenter">
            <strong
              style={{
                color: "#712885",
                whiteSpace: "nowrap",
                wordBreak: "break-word",
              }}
            >
              At Nexi Sphere,
            </strong>{" "}
            we are dedicated to providing the highest quality raw banana powder
            sourced from the best bananas. Our process preserves the natural
            nutrients, ensuring that you receive a product that is not only
            delicious but also packed with health benefits.
          </div>
          <div className="welcome-section">
            <div className="content-container">
              <div className="image-container">
                <img
                  src={banner}
                  alt="AARTI CHEM PHARMA & AARTI DYE CHEM"
                  className="banner-image"
                />
              </div>
              <div className="text-container">
                <h1 className="welcome-title">WELCOME TO NEXI SPHERE</h1>
                <p className="intro-text">
                  <strong>
                    Discover the goodness of nature in every scoop.
                  </strong>
                </p>
                <p className="welcome-description">
                  Welcome to Nexi Sphere, where our premium raw banana powder
                  embodies our commitment to quality and health. Sourced from
                  the finest bananas, our powder is rich in nutrients and
                  perfect for enhancing your meals and smoothies. We believe in
                  harnessing the natural benefits of bananas to provide a
                  versatile product that supports a healthy lifestyle. Join us
                  on this journey to transform your nutrition with a product
                  that not only tastes great but also fuels your body with
                  essential vitamins and minerals. Experience the difference
                  with Nexi Sphere as we redefine healthy eating!
                </p>

                <p className="learn-more" onClick={windowScroll}>
                  <strong>
                    <NavLink to="/about-us" className="more-about-link">
                      MORE ABOUT US <i className="fa fa-long-arrow-right"></i>
                    </NavLink>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="industries">
          <h2>Industries Using Banana Powder Products</h2>
          <div className="industries-content">
            <div className="industry-card">
              <h3>Food & Beverage Industry</h3>
              <p>
                Banana powder is a popular ingredient in baby food, health
                drinks, smoothies, and baked goods due to its natural flavor and
                nutritional profile.
              </p>
            </div>
            <div className="industry-card">
              <h3>Health & Wellness</h3>
              <p>
                With a high potassium content and resistant starch, banana
                powder is ideal for nutritional supplements, protein bars, and
                gluten-free foods.
              </p>
            </div>
            <div className="industry-card">
              <h3>Cosmetics & Personal Care</h3>
              <p>
                Banana powder is used in natural skincare products such as
                exfoliants, face masks, and hair conditioners for its soothing
                and antioxidant properties.
              </p>
            </div>
            <div className="industry-card">
              <h3>Pet Food Industry</h3>
              <p>
                Banana powder is increasingly used as a natural ingredient in
                dog and cat treats due to its digestibility and nutritional
                benefits.
              </p>
            </div>
          </div>
        </section>
        <section className="features">
          <h2>Why Choose Our Raw Banana Powder?</h2>
          <div className="features-content">
            <div className="feature-card">
              <h3>Extensive Product Range</h3>
              <p>• Extensive product range for various industries.</p>
            </div>
            <div className="feature-card">
              <h3>Reliable Supplier Network</h3>
              <p>
                • Reliable supplier network for consistent quality and
                availability.
              </p>
            </div>
            <div className="feature-card">
              <h3>Competitive Pricing</h3>
              <p>• Competitive pricing and customizable packaging options.</p>
            </div>
          </div>
          {/* <img src={featuresImage} alt="Features" className="features-image" /> */}
        </section>
      </div>
    </div>
  );
};

export default HomePage;
