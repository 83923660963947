import React from "react";
import bananaBanner from "../assets/images/banana-banner.jpg";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="tp-banner">
      <div className="banner">
        <img src={bananaBanner} alt="" />
      </div>
      <div className="tp-page-width">
        <div className="tp-ban-content">
          <div className="tp-ban-con">
            <div className="tp-ban-title">
              <h5>START SOURCING WITH US</h5>
              <h2>Premium &amp; Quality Banana Powder</h2>
              <div className="tp-ban-btn-box">
                <div className="tp-ban-btn">
                  <Link to="/about-us" className="tp-btn-hover">
                    Learn More
                  </Link>
                  <Link to="/ripe-banana-powder" className="tp-btn-hover">
                    Our Products
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
