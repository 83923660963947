import React, { useState } from "react";
import SubHeader from "../../components/SubHeader";
import OurMission from "./OurMission";
import OurStory from "./OurStory";
import OurUsp from "./OurUsp";
import OurVision from "./OurVision";
import Sidebar from "./Sidebar";

const Aboutpage = () => {
  const [curruntPath, setCurruntPath] = useState("Our Mission");

  const menuItems = [
    { name: "Our Mission" },
    { name: "Our Vision" },
    { name: "Our Story" },
    { name: "Our Usp" },
  ];

  return (
    <>
      <div className="rightsidebar">
        <div className="labora_page_loader" style={{ display: "none" }} />
        <div id="wrapper">
          <SubHeader subHeaderText={curruntPath} />
          <div id="main">
            <div id="primary" className="pagemid">
              <div className="inner">
                <main className="content-area">
                  {curruntPath === "Our Mission" && <OurMission />}
                  {curruntPath === "Our Vision" && <OurVision />}
                  {curruntPath === "Our Story" && <OurStory />}
                  {curruntPath === "Our Usp" && <OurUsp />}
                </main>
                <Sidebar
                  setCurruntPath={setCurruntPath}
                  menuItems={menuItems}
                  initName={"Our Mission"}
                />
              </div>
            </div>
          </div>
          <div className="banana-container">
            <h2 className="section-title">
              Why Choose Nexi Sphere for Banana Powder Trading?
            </h2>
            <div className="features">
              <ul>
                <li>
                  🌟 <strong>Extensive product range</strong> for various
                  industries.
                </li>
                <li>
                  🔗 <strong>Reliable supplier network</strong> for consistent
                  quality and availability.
                </li>
                <li>
                  💰 <strong>Competitive pricing</strong> and customizable
                  packaging options.
                </li>
              </ul>
            </div>

            <h2 className="section-title">
              Industries Using Banana Powder Products
            </h2>
            <div className="industries">
              <div className="industry">
                <h3>🍽️ Food & Beverage Industry:</h3>
                <p>
                  Banana powder is a popular ingredient in baby food, health
                  drinks, smoothies, and baked goods due to its natural flavor
                  and nutritional profile.
                </p>
              </div>
              <div className="industry">
                <h3>🏋️ Health & Wellness:</h3>
                <p>
                  With a high potassium content and resistant starch, banana
                  powder is ideal for nutritional supplements, protein bars, and
                  gluten-free foods.
                </p>
              </div>
              <div className="industry">
                <h3>💄 Cosmetics & Personal Care:</h3>
                <p>
                  Banana powder is used in natural skin care products such as
                  exfoliants, face masks, and hair conditioners for its soothing
                  and antioxidant properties.
                </p>
              </div>
              <div className="industry">
                <h3>🐾 Pet Food Industry:</h3>
                <p>
                  Banana powder is increasingly used as a natural ingredient in
                  dog and cat treats due to its digestibility and nutritional
                  benefits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutpage;
